import React, { useEffect } from 'react'
import { Link } from "gatsby"

const LoadingResults = () => (
  <div className="loading">
    <h5 className="loading__text">Loading. . .  Please wait.</h5>
    <div className="spinner-border text-light" role="status">
      <span className="visually-hidden">Loading...</span>
    </div>
  </div>
)

const ResultItem = ({ handleResultClick, company }) => {

  return (
    <li className="result__item">

      <div className="item__data">
        <span className="item__title">{ company.name }</span>
        <span className="item__address">{ company.address }</span>
      </div>
  
      <div className="item__state justify-content-end">
        <span className="item__link item__link--available">
          <Link onClick={ (e) => handleResultClick(e, 'interested', company) } to="/">
            Select <span className="mobile-hidden">workplace</span> ›
          </Link>
        </span>
      </div>
  
    </li>
  )
}

const ResultList = ({handleResultClick, companies, query}) => (
  <>
    <div className="row">
      <div className="col-12">

        <h4 className="results__headline">{ companies?.length } Results for <b>“{ query }”</b></h4>

      </div>
    </div>

    <div className="row">
      <div className="col-12">

        <ul className="results__list">

          { companies?.map( company => <ResultItem key={company.id} handleResultClick={ handleResultClick } company={ company } /> ) }

        </ul>
        
      </div>
    </div>

    <div className="row">
      <div className="col-12">
        <Link onClick={ (e) => handleResultClick(e, 'notify') } className="button button--results button--transparent" to="/">Click here if your workplace is not listed</Link>
      </div>
    </div>
  </>
)

export default function RegistrationResult ({ loadingData, handleResultClick, companies, query, inHome }) {
  useEffect(() => {
    if(!loadingData && inHome){
      window.scrollTo(0, 0);
    }
  }, [companies, loadingData])
  return (
    <div className="results-container">
      <div className="container">

        {
          loadingData
            ? <LoadingResults />
            : <ResultList handleResultClick={handleResultClick} companies={companies} query={query}/>
        }

      </div>
    </div>
  )
}