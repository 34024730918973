const countryList = [
	"United Kingdom",
  "Antigua and Barbuda",
  "Australia",
  "Austria",
  "Bangladesh",
  "Barbados",
  "Belgium",
  "Belize",
  "Botswana",
  "Brunei",
  "Bulgaria",
  "Cameroon",
  "Canada",
  "Croatia",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Dominica",
  "Estonia",
  "Eswatini",
  "Fiji",
  "Finland",
  "France",
  "Gabon",
  "Germany",
  "Ghana",
  "Greece",
  "Grenada",
  "Guyana",
  "Hungary",
  "India",
  "Ireland",
  "Italy",
  "Jamaica",
  "Kenya",
  "Kiribati",
  "Latvia",
  "Lesotho",
  "Lithuania",
  "Luxembourg",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Malta",
  "Mauritius",
  "Mozambique",
  "Namibia",
  "Nauru",
  "Netherlands",
  "New Zealand",
  "Nigeria",
  "Pakistan",
  "Papua New Guinea",
  "Poland",
  "Portugal",
  "Romania",
  "Rwanda",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Vincent and the Grenadines",
  "Samoa",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "South Africa",
  "Spain",
  "Sri Lanka",
  "Sweden",
  "Tanzania",
  "The Bahamas",
  "The Gambia",
  "Togo",
  "Tonga",  
  "Trinidad and Tobago",  
  "Tuvalu",  
  "Uganda",  
  "Vanuatu",  
  "Zambia",  
];

export default countryList