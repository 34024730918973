import React from 'react';

// import "bootstrap/dist/css/bootstrap.min.css"
import "@fontsource/libre-franklin/700.css"
import "@fontsource/libre-franklin/600.css"
import "@fontsource/libre-franklin/500.css"
import "@fontsource/libre-franklin/400.css"
import "@fontsource/newsreader/800.css"
import "@fontsource/newsreader/700.css"
import "@fontsource/newsreader/400.css"
import "@fontsource/roboto-mono/700.css"
import "@fontsource/roboto-mono/400.css"

import Layout from './src/components/layout';
import { SiteProvider } from './src/components/SiteContext';
import { QueryProvider } from './src/components/QueryContext';
import { FormProvider } from './src/components/FormContext';
import { WardProvider } from './src/components/WardContext';

export function wrapPageElement({ element, props }) {
  return <Layout {...props }>{ element }</Layout>
}

export function wrapRootElement({ element }) {
  return (
    <QueryProvider>
      <FormProvider>
        <WardProvider>
          <SiteProvider>
            { element }
          </SiteProvider>
        </WardProvider>
      </FormProvider>
    </QueryProvider>
  )
}