// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-become-a-councillor-js": () => import("./../../../src/pages/become-a-councillor.js" /* webpackChunkName: "component---src-pages-become-a-councillor-js" */),
  "component---src-pages-calculator-js": () => import("./../../../src/pages/calculator.js" /* webpackChunkName: "component---src-pages-calculator-js" */),
  "component---src-pages-candidates-js": () => import("./../../../src/pages/candidates.js" /* webpackChunkName: "component---src-pages-candidates-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-contact-test-js": () => import("./../../../src/pages/contact-test.js" /* webpackChunkName: "component---src-pages-contact-test-js" */),
  "component---src-pages-cookies-policy-js": () => import("./../../../src/pages/cookies-policy.js" /* webpackChunkName: "component---src-pages-cookies-policy-js" */),
  "component---src-pages-email-js": () => import("./../../../src/pages/email.js" /* webpackChunkName: "component---src-pages-email-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-faq-nomination-candidates-js": () => import("./../../../src/pages/faq-nomination-candidates.js" /* webpackChunkName: "component---src-pages-faq-nomination-candidates-js" */),
  "component---src-pages-find-your-ward-js": () => import("./../../../src/pages/find-your-ward.js" /* webpackChunkName: "component---src-pages-find-your-ward-js" */),
  "component---src-pages-form-test-js": () => import("./../../../src/pages/form-test.js" /* webpackChunkName: "component---src-pages-form-test-js" */),
  "component---src-pages-frequently-asked-questions-js": () => import("./../../../src/pages/frequently-asked-questions.js" /* webpackChunkName: "component---src-pages-frequently-asked-questions-js" */),
  "component---src-pages-home-search-js": () => import("./../../../src/pages/home-search.js" /* webpackChunkName: "component---src-pages-home-search-js" */),
  "component---src-pages-home-v-2-js": () => import("./../../../src/pages/home-v2.js" /* webpackChunkName: "component---src-pages-home-v-2-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-key-dates-js": () => import("./../../../src/pages/key-dates.js" /* webpackChunkName: "component---src-pages-key-dates-js" */),
  "component---src-pages-legal-notes-js": () => import("./../../../src/pages/legal-notes.js" /* webpackChunkName: "component---src-pages-legal-notes-js" */),
  "component---src-pages-places-js": () => import("./../../../src/pages/places.js" /* webpackChunkName: "component---src-pages-places-js" */),
  "component---src-pages-postal-js": () => import("./../../../src/pages/postal.js" /* webpackChunkName: "component---src-pages-postal-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-registration-guide-js": () => import("./../../../src/pages/registration-guide.js" /* webpackChunkName: "component---src-pages-registration-guide-js" */),
  "component---src-pages-request-online-registration-code-js": () => import("./../../../src/pages/request-online-registration-code.js" /* webpackChunkName: "component---src-pages-request-online-registration-code-js" */),
  "component---src-pages-resources-js": () => import("./../../../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-results-js": () => import("./../../../src/pages/results.js" /* webpackChunkName: "component---src-pages-results-js" */),
  "component---src-pages-sole-trader-partner-js": () => import("./../../../src/pages/sole-trader-partner.js" /* webpackChunkName: "component---src-pages-sole-trader-partner-js" */),
  "component---src-pages-standing-registration-js": () => import("./../../../src/pages/standing-registration.js" /* webpackChunkName: "component---src-pages-standing-registration-js" */),
  "component---src-pages-steps-pdf-js": () => import("./../../../src/pages/steps-pdf.js" /* webpackChunkName: "component---src-pages-steps-pdf-js" */),
  "component---src-pages-subscribe-js": () => import("./../../../src/pages/subscribe.js" /* webpackChunkName: "component---src-pages-subscribe-js" */),
  "component---src-pages-terms-and-conditions-apply-js": () => import("./../../../src/pages/terms-and-conditions-apply.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-apply-js" */),
  "component---src-pages-test-page-js": () => import("./../../../src/pages/test_page.js" /* webpackChunkName: "component---src-pages-test-page-js" */),
  "component---src-pages-unlisted-company-js": () => import("./../../../src/pages/unlisted-company.js" /* webpackChunkName: "component---src-pages-unlisted-company-js" */),
  "component---src-pages-updates-js": () => import("./../../../src/pages/updates.js" /* webpackChunkName: "component---src-pages-updates-js" */),
  "component---src-pages-voter-registration-js": () => import("./../../../src/pages/voter-registration.js" /* webpackChunkName: "component---src-pages-voter-registration-js" */),
  "component---src-pages-voting-js": () => import("./../../../src/pages/voting.js" /* webpackChunkName: "component---src-pages-voting-js" */),
  "component---src-pages-voting-registration-js": () => import("./../../../src/pages/voting-registration.js" /* webpackChunkName: "component---src-pages-voting-registration-js" */),
  "component---src-pages-voting-residents-js": () => import("./../../../src/pages/voting-residents.js" /* webpackChunkName: "component---src-pages-voting-residents-js" */),
  "component---src-pages-voting-workers-js": () => import("./../../../src/pages/voting-workers.js" /* webpackChunkName: "component---src-pages-voting-workers-js" */),
  "component---src-pages-ward-template-js": () => import("./../../../src/pages/ward-template.js" /* webpackChunkName: "component---src-pages-ward-template-js" */),
  "component---src-pages-wards-js": () => import("./../../../src/pages/wards.js" /* webpackChunkName: "component---src-pages-wards-js" */),
  "component---src-templates-event-js": () => import("./../../../src/templates/Event.js" /* webpackChunkName: "component---src-templates-event-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/Post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-thank-you-js": () => import("./../../../src/templates/ThankYou.js" /* webpackChunkName: "component---src-templates-thank-you-js" */),
  "component---src-templates-updates-tag-js": () => import("./../../../src/templates/UpdatesTag.js" /* webpackChunkName: "component---src-templates-updates-tag-js" */),
  "component---src-templates-ward-js": () => import("./../../../src/templates/Ward.js" /* webpackChunkName: "component---src-templates-ward-js" */)
}

