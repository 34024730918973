import React, { useContext } from 'react'
import LogoCity from './icons/LogoCity';
import StepContext from './SiteContext';

// = = = = = = = = = = = = =
// Component
// - - - - - - - - - - - - -
// Add id to each Heading with title as a value
const PreviewInput = ({data, label}) => (
  <span className={`form-letter__input ${data ? 'filled' : ''}`}>{ data ? data : label }</span>
)

export default function EmailPreview ({ selectedCompany }) {
  const [formData] = useContext(StepContext);

  return (
    <div className="form-letter">
      <p className="form-letter__headline">Your email preview:</p>
      <div className="form-letter__content">
        <p>Dear Sir or Madam</p>
        <p>
          We are writing to you as the voter registration contact at&nbsp;
          <PreviewInput data={formData.company ? formData.company : selectedCompany.name} label="[Organisation]" />
          .
        </p>
        <p>
          Your colleague&nbsp;
          <PreviewInput data={formData.firstName} label="[Your First Name]" /> 
          &nbsp;
          <PreviewInput data={formData.lastName} label="[Your Last Name]" />
          &nbsp;
          has let us know that they are interested in being added to your workplace's list of voters for City of London elections.
        </p>
        <p>
          Please get in touch with them on&nbsp;
          <PreviewInput data={formData.email} label="[Email]" />
          &nbsp;
          The registration deadline is November 30th each year.
        </p>
        <p>Registering to vote is an important way for your colleagues to help shape the City’s future.</p>
        <p>If you have any questions, please don’t hesitate to contact us or visit www.speakforthecity.com</p>
        <p>Yours faithfully,</p>
        <p>The City of London Elections Team</p>
      </div>
      <LogoCity size={ 'small' }/>
    </div>
  )
}