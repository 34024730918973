import React, { useState } from 'react'
import { Link } from "gatsby"
import Logo from './icons/Logo'
import LogoDragon from './icons/LogoDragon'
import { StaticImage } from 'gatsby-plugin-image';

export default function Nav() {
  const [ ariaExpanded, setAriaExpanded ] = useState(false);

  // = = = = = = = = = = = = =
  // Event Listeners

  // Close the menu in mobile
  const handleClick = () => {
    const newState = !ariaExpanded;
    setAriaExpanded(newState);
  }

  return (
    <div className="navbar-wrapper">
      <Link className="navbar-brand" to="/">
        {/* <div className="logo-image">
          <LogoDragon />
        </div> */}
        <Logo/>
      </Link>
    
      <div className="container--navbar">
        <nav className="row navbar navbar-expand-lg">
          <div className="container-fluid">

            <button onClick={ handleClick } className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded={ ariaExpanded } aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
              <span className="navbar-toggler-icon"></span>
              <span className="navbar-toggler-icon"></span>
            </button>

            <div className={`collapse navbar-collapse ${ ariaExpanded ? 'show' : '' }`} id="navbarNav">
              <ul className="navbar-nav">

                <li className="nav-item">
                  <Link onClick={ () => setAriaExpanded(false) } className="nav-link" to="/about">About</Link>
                </li>

                <li className="nav-item">
                  <Link onClick={ () => setAriaExpanded(false) } className="nav-link" to="/voting-registration">Voting</Link>
                </li>

                <li className="nav-item">
                  <Link onClick={ () => setAriaExpanded(false) } className="nav-link" to="/become-a-councillor">Standing</Link>
                </li>

                <li className="nav-item">
                  <Link onClick={ () => setAriaExpanded(false) } className="nav-link active" to="/contact">Contact</Link>
                </li>

              </ul>
            </div>

            <div className='logo-corp'>
              <LogoDragon />
            </div>

          </div>
        </nav>
      </div>

      
    </div>
  )
}
