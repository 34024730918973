import React, { useState } from 'react'

const QueryContext = React.createContext();

export function QueryProvider({ children }) {
  const [ query, setQuery ] = useState('');

  return(
    <QueryContext.Provider value={[query, setQuery]}>
      { children }
    </QueryContext.Provider>
  )
}

export default QueryContext;