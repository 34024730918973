import React from 'react'
import EmailPreview from './EmailPreview';

export default function RegistrationForm ({ children, preview, formData, selectedCompany }) {

  return (
    <div className="form-container">
      <div className="container">

      <div className="row">
        <div className="col-md-6">

          <h4 className="form__headline">{ formData.form_headline }</h4>

          <div className="form">

            { children }

          </div>

        </div>

        <div className="col-md-5 offset-md-1">

          { preview ? <EmailPreview selectedCompany={selectedCompany} /> : ''}
          
        </div>

      </div>

      </div>
    </div>
  )
}