import React, { useContext, useState, useEffect } from 'react';
import { graphql, Link, useStaticQuery } from "gatsby";
import axios from 'axios';
import ReactMarkdown from 'react-markdown';

import QueryContext from './QueryContext';

import IconClose from "./icons/IconClose";
import RegistrationForm from './RegistrationForm';
import RegistrationFinal from "./RegistrationFinal";
import RegistrationResult from "./RegistrationResult";
import CompanyEmailForm from './forms/CompanyEmailForm';
import CompanyUnlistedForm from './forms/CompanyUnlistedForm';

export default function CheckBanner ({ type = '', inHome=false, description=null, path = '' }) {
 
  const [ query, setQuery ] = useContext(QueryContext);
  const [ filteredData, setFilteredData ] = useState([]);
  const [ loadingData, setLoadingData ] = useState(false);
  const [ companiesData, setCompaniesData ] = useState([]);
  const [ selectedCompany, setSelectedCompany ] = useState({});
  const [ bannerState, setBannerState ] = useState('initial');

  // = = = = = = = = 
  // Forestry Query
  const data = useStaticQuery(graphql`
    query RegistrationForms {
      interestForm: allMarkdownRemark(filter: {frontmatter: {form_title: {eq: "Express Interest Form"}}}) {
        nodes {
          frontmatter {
            form_headline
            form_introduction
            form_consent
          }
        }
      },
      unlistedForm: allMarkdownRemark(filter: {frontmatter: {form_title: {eq: "Unlisted Company Form"}}}) {
        nodes {
          frontmatter {
            form_headline
            form_introduction
            form_consent
          }
        }
      },
      electionsDate:  markdownRemark(frontmatter: {date_id: {eq: "ward-elections"}}) {
        frontmatter {
          #elections_date(formatString: "M/D/Y")
          deadline(formatString: "M/D/Y")
        }
      },
      pageHeadline: markdownRemark(frontmatter: {page_title: {eq: "Voter Registration"}}) {
        html
      }
    }
  `)

  const html = data.pageHeadline.html;
  // = = = = = = = = 
  // Hooks

  // Initial Hook: retrieves companies Data
  const fetchCompanies = async () => {

    setBannerState('initial');
    setLoadingData(true);
    
    await axios.get('/.netlify/functions/getCompanies')
      .then( ({ data }) => {
        setCompaniesData(data.data);
        filterData(data.data);
      })
      .then( response => {
        setLoadingData(false);
      })
      .catch( error => console.log(error) )
  }

  useEffect( () => fetchCompanies(), []);

  //  Returns company
  const searchData = (company) => {
    return (
      company.name
        .toLowerCase()
        .replace("&", "and")
        .replace("-", "")
        .replace(/\s/g, "")
        .includes(query
          .toLowerCase()
          .replace("&", "and")
          .replace("-", "")
          .replace(/\s/g, "")
        )
    );
  }

  // Filter Data when query updates
  const filterData = (companiesData) => {
    if(query) {
      const filteredCompanies = companiesData?.filter(
        company => searchData(company)
      );
      query.length > 0 ? setFilteredData(filteredCompanies) : setFilteredData([]);
      query.length > 1 ? setBannerState('querying') : setBannerState('');
    }
  }

  const checkQuery = () => {
    // return query ? '' : hideBanner(); 
  }

  useEffect(() => filterData(companiesData), [query]);
  useEffect(() => checkQuery(), [query]);
  useEffect(() => filterData(companiesData), [loadingData]);

  // = = = = = = = = 
  // Events Functions

  // Get Diff Date
  const getDaysDifference = () => {
    const presentDate = new Date();
    // const electionDate = new Date(data.electionsDate.frontmatter.elections_date);
    // const electionDate = new Date('11/30/2024');
    const electionDate = new Date(data.electionsDate.frontmatter.deadline);
    // Plus 1  to include the election date
    const daysDiff = Math.ceil((electionDate - presentDate) / (1000 * 3600 * 24)) + 1;
    return daysDiff;
  }

  // Sets query to current input value
  const handleInputChange = e => {
    setQuery(e.target.value);
  }

  // Change selectedCompany and bannerState states depending on target key attribute
  const handleResultClick = ( e, key, company ) => {
    e.preventDefault();

    // Scroll to the top of the window so user see the form Form
    if (typeof window !== `undefined`) {
      window.scrollTo({top: 0, behavior: 'smooth'});
    }
    
    if (company) setSelectedCompany(company);

    switch (key) {
      case 'interested':
        setBannerState( 'interested' );
        break;
      case 'notify':
        setBannerState( 'notify' );
        break;
      case 'final':
        setBannerState( 'final' );
        break;
    
      default:
        setBannerState( '' );
        break;
    }
  }

  const handleSubmitClick = e => {
    e.preventDefault();
  }

  const hideBanner = () => {
    setBannerState('none');
    setQuery('');
  }


  return (
    <>
      <section className={`banner-wrapper ${bannerState === "none" ? "d-none" : null} ${type ? `banner-wrapper--${type}` : ''}`}>

        <div className="check-banner">

          <div className="registration-date">
            {
              getDaysDifference() > 0
                &&
                <>
                  <div className="registration-date__countdown"><span className="registration-date__number">{getDaysDifference()}</span> days</div>
                  <div className="registration-date__text">left to register to vote</div>
                </>
            }
            {
              getDaysDifference() <= 0 && type === "banner"
                &&
              <div className="registration__text">Register your interest in becoming a voter at your organisation</div>
            }
          </div>
          {
            (bannerState !== 'interested' && bannerState !== 'notify' && bannerState !== 'final' && description === null)
              &&
           
        
            <div className={`check-banner__intro ${type === 'banner' ? 'd-none' : '' }`} dangerouslySetInnerHTML={{__html: html }} />
          }
          {
            description && (
              <div className="check-banner__intro banner" dangerouslySetInnerHTML={{__html: html }} />
           
            )
          }

          <div className="form-wrapper">
            <form className={`${ ( bannerState === 'final' || bannerState === 'notify' || bannerState === 'interested' || bannerState === 'waitingList' ) ? 'd-none' : '' }`}>
              <input 
                onChange={ (e) => handleInputChange(e) }
                type="text"
                placeholder="Who do you work for?"
                name=""
                id=""
                value={query ? query : ''}
              />
              <input
                onClick={ (e) => handleSubmitClick(e) }
                className="button button--transparent"
                type="submit"
                value="Become a voter"
              />
            </form>
            <IconClose 
              type={ type }
              bannerState={ bannerState }
              hideBanner = { hideBanner } 
            />
          </div>
          
        </div>

        {
          bannerState === 'querying' 
            ? <RegistrationResult
                loadingData = {loadingData}
                setSelectedCompany = { setSelectedCompany }
                handleResultClick={ handleResultClick } 
                companies={ filteredData } 
                query={ query }
                inHome={inHome}
              /> 
            : ''
        }
        {
          bannerState === 'interested'
            ? <RegistrationForm selectedCompany={selectedCompany} formData={ data.interestForm.nodes[0].frontmatter } preview={ true }>
                <CompanyEmailForm setQuery={setQuery} formIntro={data.interestForm.nodes[0].frontmatter.form_introduction} selectedCompany={selectedCompany} setBannerState={ setBannerState }/>
              </RegistrationForm>
            : ''
        }
        {
          bannerState === 'notify' 
            ? <RegistrationForm formData={ data.unlistedForm.nodes[0].frontmatter }>
                <CompanyUnlistedForm setBannerState={ setBannerState }/>
              </RegistrationForm>
            : ''
        }
        {
          bannerState === 'final' 
            ? <RegistrationFinal /> : ''
        }
      </section>
    </>
  )
}