import React, { useState } from 'react'

const FormContext = React.createContext();

export function FormProvider({ children }) {
  const [ formData, setFormData ] = useState({});

  return(
    <FormContext.Provider value={[formData, setFormData]}>
      { children }
    </FormContext.Provider>
  )
}

export default FormContext;