import React, { useState } from 'react'

const WardContext = React.createContext();

export function WardProvider({ children }) {
  const [ currentWard, setCurrentWard ] = useState('');

  return(
    <WardContext.Provider value={[currentWard, setCurrentWard]} >
      { children }
    </WardContext.Provider>
  )
}

export default WardContext;