/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { useEffect, useContext } from 'react'
import PropTypes from "prop-types"
import { globalHistory } from '@reach/router'

import "../styles/main.scss"
import Header from "./Header"
import OverlayGrid from "./OverlayGrid"
import Footer from "../components/Footer"

import QueryContext from '../components/QueryContext';
import ModalWindowHomeV2 from './ModalWindowHomev2'

const Layout = ({ children, type, path }) => {
  // const [ query, setQuery ] = useContext(QueryContext);

  // useEffect(() => {
  //   console.log(globalHistory);
  //   if(path !== '/voter-registration/') {
  //     return globalHistory.listen(({ action }) => {
  //       if (action === 'PUSH') setQuery('')
  //     })
  //   }
  // }, [setQuery])
  const isBrowser = typeof window !== "undefined"
  
  const fillCurator = () => {
    var i, e, d = document, s = "script";
    i = d.createElement("script");
    i.async = 1;
    i.src = "https://cdn.curator.io/published/f1142998-0d2e-4846-ae18-311454208e2e.js";
    e = d.getElementsByTagName(s)[0];e.parentNode.insertBefore(i, e);
  }


  return (
    <>
      <Header path={ path } type={ type } />
      {/* <ModalWindowHomeV2 /> */}
      <OverlayGrid show={ false } />
      <main>{ children }</main>

      <Footer />

      {/* <!-- The Javascript can be moved to the end of the html page before the </body> tag --> */}
      <script type="text/javascript">
      {/* curator-feed-default-feed-layout */}
      {
        isBrowser ? fillCurator() : ''
      }
      </script>
      <script>
        {`
          <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-MXDZFMN"
          height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
          `}
      </script>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout