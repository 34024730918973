import React, { useState } from 'react';
import IconSelect from '../icons/IconSelect';
import countryList from './Countries'
import ReactMarkdown from 'react-markdown';
//todo marcel
export default function CompanyEmailFormStep2 ({ htmlFormConsent, setStep, formData, setFormData, handleChange, postingData, handleSubmit }) {
	const [errorMessage, setErrorMessage] = useState('');
  const [inputError, setInputError] = useState('');


  const handleBackButton = (e) => {
    e.preventDefault();
     // Scroll to the top of the window so user see the form Form
    if (typeof window !== `undefined`) { 
      window.scrollTo({top: 0, behavior: 'smooth'});
    }

		setStep(1);
  }

  const handleClick = (e) => {
		
    if(formData.workingSince === 'None'){
      e.preventDefault();
      setErrorMessage('*If none of the above apply, you are not eligible to register as a voter this year');
      return;
    }
		if(!formData.nationality) {
			e.preventDefault();
      setInputError('nationality');
      setErrorMessage('*You must be an EU or Commonwealth citizen in order to register');
      return;
    }
  }

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setFormData({ 
      ...formData,
      [name]: checked ? 'yes' : 'no',
    });
  };

  return(
    <>
      <div className="field-group">
        <fieldset className="fieldset-wrapper ">
          <legend>Please select one:</legend>

					<div className="checkbox-wrapper custom-radio">
						<label htmlFor="workingSince1" className="checkbox-container">I have worked continuously for this organisation in the City since September last year.
						</label>
            <input 
								onChange={ (e) => handleChange(e) } 
								type="radio"
                id="workingSince1"
								name="workingSince"
								value="I have worked continuously for this organisation in the City since September last year." 
								checked={ formData.workingSince === 'I have worked continuously for this organisation in the City since September last year.' ? true : false} />
					</div>
					
					<div className="checkbox-wrapper custom-radio">
						<label htmlFor="workingSince2" className="checkbox-container">I have worked for this organisation in the City for a combined period of five years - with some of this taking place in the past five years.
							
							{/* <span className="checkmark"></span> */}
						</label>
            <input 
								onChange={ (e) => handleChange(e) } 
								type="radio"
                id="workingSince2"
								name="workingSince"
								value="I have worked for this organisation in the City for a combined period of five years - with some of this taking place in the past five years." 
								checked={ formData.workingSince === 'I have worked for this organisation in the City for a combined period of five years - with some of this taking place in the past five years.' ? true : false} />
					</div>
					
					<div className="checkbox-wrapper custom-radio">
						<label htmlFor="workingSince3" className="checkbox-container">I have worked in the City for any organisation - for a combined period of ten years or more.
							
							
						</label>
            <input 
								onChange={ (e) => handleChange(e) } 
								type="radio"
                id="workingSince3"
								name="workingSince"
								value="I have worked in the City for any organisation - for a combined period of ten years or more." 
								checked={ formData.workingSince === 'I have worked in the City for any organisation - for a combined period of ten years or more.' ? true : false} />
					</div>

					<div className="checkbox-wrapper custom-radio">
						<label htmlFor="workingSince4" className="checkbox-container">I have been a member of this organisation’s Board of Directors or Governing Body since 1st September last year.
							
							
						</label>
            <input 
								onChange={ (e) => handleChange(e) } 
								type="radio"
								name="workingSince4"
                id="workingSince4"
								value="I have been a member of this organisation’s Board of Directors or Governing Body since 1st September last year." 
								checked={ formData.workingSince === 'I have been a member of this organisation’s Board of Directors or Governing Body since 1st September last year.' ? true : false} />
					</div>

          <div className="checkbox-wrapper custom-radio">
						<label htmlFor="workingSince5" className="checkbox-container">None of the above
							
			
						</label>
            <input 
								onChange={ (e) => handleChange(e) } 
								type="radio"
								name="workingSince"
                id="workingSince5"
								value="None" 
								checked={ formData.workingSince === 'None' ? true : false} />
					</div>

        </fieldset>
      </div>

      <div className="field-group">
        <div className="input-wrapper">
          <label htmlFor="select-nationality" className="select__label">Citizenship (required):</label>
          
          <div className="select__wrapper  select--nationality mb-1">
            
            <select  
							onChange={ (e) => handleChange(e) } 
							className={`select__input ${inputError ===  "nationality" ? 'alert-border' : null}`} 
							name="nationality" 
							id="select-nationality"
							value={formData.nationality ? formData.nationality : ''}
						>
              <option className="select__option" value="">Select</option>
              {
                countryList.map(country => <option key={country} className="select__option" value={country}>{country}</option>)
              }
            </select>
            
            <div className="select__icon">
              <IconSelect />
            </div>
            
          </div>
          <span className="small">Please note, only UK, Commonwealth, and qualifying EU citizens can register to vote in UK elections.</span>
          <p className="small">
              EU citizens can only be appointed as a voter on the ward list if:<br />
              <ul className="mb-0">
                <li>They are a citizen of Denmark, Luxembourg, Poland, Portugal and Spain who is resident in the UK; or</li>
                <li>They are a citizen of any other EU country who on or before 31 December 2020 was legally resident in the UK</li>
              </ul>
              Citizens from Ireland, Malta, or Cyprus will retain their voting rights.
          </p>
        </div>
      </div>


      <p className="field-intro">If we have a voter registration contact at your organisation, we will send them this information. In the event we do not, could you suggest an appropriate person at your organisation, who we could contact about this? (optional).</p>
      <div className="field-group">
        <div className="input-wrapper">
          <input 
            onChange={(e) => handleChange(e)} 
            type="text" 
            value={formData.contactName ? formData.contactName : ""} 
            name="contactName"
            id="contact-job-name" 
            placeholder="Name"
          />
          <span className={`input__custom-label ${ formData.contactName ? 'show' : '' }`}>Name</span>
        </div>
      </div>

      <div className="field-group">
        <div className="input-wrapper">
          <input 
            onChange={(e) => handleChange(e)} 
            type="text" 
            value={formData.contactEmail ? formData.contactEmail : ""} 
            name="contactEmail"
            id="contact-email" 
            placeholder="Email"
          />
          <span className={`input__custom-label ${ formData.contactEmail ? 'show' : '' }`}>Email</span>
        </div>
      </div>

      <div className="field-group">
        <div className="input-wrapper">
          <input 
            onChange={(e) => handleChange(e)} 
            type="text" 
            value={formData.contactJobTitle ? formData.contactJobTitle : ""} 
            name="contactJobTitle"
            id="contact-job-title" 
            placeholder="Job title"
          />
          <span className={`input__custom-label ${ formData.contactJobTitle ? 'show' : '' }`}>Job title</span>
        </div>
      </div>

      <div className="form__consent">
        <ReactMarkdown className="consent__text">{htmlFormConsent}</ReactMarkdown>

        <div className="consent__inputs d-none">
          <div className="radio-wrapper">
            <input 
              onChange={ (e) => handleChange(e) } 
              id="yes" 
              name="consent" 
              type="radio" 
              value="yes" 
              checked={ formData.consent === 'yes' ? true : false} 
            />
            <label htmlFor="yes">Yes</label>
          </div>

          <div className="radio-wrapper">
            <input 
              onChange={ (e) => handleChange(e) } 
              id="no" 
              name="consent" 
              type="radio"
              value="no" 
              checked={ (formData.consent === 'no' || formData.consent === undefined) ? true : false} 
            />
            <label htmlFor="no">No</label>
          </div>

        </div>  



        {/* NEW CONSENTS */}
        <div className="consent__inputs mb-2">
          <div className="checkbox-wrapper">
            <input 
              className='checkmark' 
              onChange={ (e) => handleCheckboxChange(e) } 
              id="chkconsent1" 
              name="chkconsent1" 
              type="checkbox"             
            />
            <label htmlFor="chkconsent1">Communications related to the Speak for the City election engagement campaign, including information on my registration status</label>
          </div>
          <div className="checkbox-wrapper">
            <input 
              className="checkmark" 
              onChange={ (e) => handleCheckboxChange(e) } 
              id="chkconsent2" 
              name="chkconsent2" 
              type="checkbox"              
            />
            <label htmlFor="chkconsent2">Community engagement updates, such as information about upcoming events and consultations</label>  
          </div>
        </div>



        <div className="consent__text">
          <p>For full details on how the City of London Corporation uses your information, <a href="/privacy-policy">please see our privacy notice here</a></p>
          <p><small>Please note that you can withdraw your consent at any time by contacting vote@cityoflondon.gov.uk</small></p>
        </div>
      </div>

			<div className="alert-wrapper">
        <p className="alert-text">{errorMessage}</p>
      </div>

      <div className="button-group">
        <div className="button button--back">
          <button onClick={(e) => handleBackButton(e)} className="button">Back</button>
        </div>
        <div className="button button--submit">
          {
            postingData !== true
              ? <input onClick={(e) => handleClick(e)} readOnly type="submit" value="Submit" name="submit" id="company-submit" />
              
              : <div className="spinner-border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
          }
        </div>
      </div>
    </>
  )
}