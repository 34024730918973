import React from 'react'
import { graphql, useStaticQuery } from "gatsby"
import showdown from "showdown";
import ReactMarkdown from 'react-markdown';
export default function RegistrationFinal () {

  // = = = = = = = = 
  // Forestry Query
  const data = useStaticQuery(graphql`
    query FinalSectionForm {
      markdownRemark(frontmatter: {section_headline: {eq: "Success Message - Check Registration Status"}}) {
        frontmatter {
          section_content
          section_call_to_actions {
            button_label
            button_url
          }
        }
      }
    }
  `)
  
  const content = data.markdownRemark.frontmatter.section_content;
  const ctas = data.markdownRemark.frontmatter.section_call_to_actions;

  // = = = = = = = = 
  // Utils

  // Convert Markdown to HTML
  //const converter = new showdown.Converter();
  const htmlContent = content;

  return (
    <>
      <div className="final-container">
        <div className="container">

          <ReactMarkdown className="row row--headline">{htmlContent}</ReactMarkdown>
          <div className="row">

            <h5 className="final__list-headline">Did you know that workers can vote in City of London elections? Learn more about how your business can get involved.</h5>

            <ul className="final__media-list">
              {
                ctas.map( cta => (
                  <li key={cta.button_label} className={`final__media-item ${cta.button_label.toLowerCase()}`}>
                    <a href={cta.button_url} target="_blank">{cta.button_label}</a>
                  </li>
                ))
              }
            </ul>

          </div>

        </div>
      </div>
    </>
  )
}
