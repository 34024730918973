module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":650,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"sizeByPixelDensity":false,"backgroundColor":"white","quality":50,"withWebp":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-iubenda-cookie-footer/gatsby-browser.js'),
      options: {"plugins":[],"iubendaOptions":{"enableCMP":true,"googleAdditionalConsentMode":true,"whitelabel":true,"lang":"en","siteId":2332328,"cookiePolicyId":22321635,"banner":{"brandBackgroundColor":"none","brandTextColor":"black","acceptButtonDisplay":true,"customizeButtonDisplay":true,"acceptButtonColor":"#E7513D","acceptButtonCaptionColor":"#FFFFFF","customizeButtonColor":"#DADADA","customizeButtonCaptionColor":"#010101","rejectButtonDisplay":true,"rejectButtonColor":"#FFFFFF","rejectButtonCaptionColor":"#E7513D","listPurposes":true,"position":"bottom","textColor":"#FFFFFF","backgroundColor":"#010101"}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-5PZ9SS1LY9"],"gtagConfig":{"anonymize_ip":true,"cookie_expires":0},"pluginConfig":{"head":true,"respectDNT":true,"exclude":["/preview/**","/do-not-track/me/too/"],"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0}},
    },{
      plugin: require('../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
