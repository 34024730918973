import React, { useState } from 'react'

const SiteContext = React.createContext();

export function SiteProvider({ children }) {
  const [ currentStep, setCurrentStep ] = useState(0);

  return(
    <SiteContext.Provider value={[currentStep, setCurrentStep]} >
      { children }
    </SiteContext.Provider>
  )
}

export default SiteContext;