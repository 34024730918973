import * as React from "react"

export default function IconSelect() {
  return (
    <svg 
      width="9" 
      height="7" 
      viewBox="0 0 9 7" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
    >
      <path 
        d="M4.5 7L0.602886 0.250001L8.39711 0.25L4.5 7Z" 
        fill="black"
      />
    </svg>

  )
}